import axios from "axios"
import axiosRetry from "axios-retry"

import { CLIENT_SIDE_BASE_URL, REQUEST_TYPE, SERVER_SIDE_BASE_URL } from "@app/api/constants"
import { checkAccessToken, getAccessTokenFromCookies } from "@app/features/user/utils"

import { getHeaders } from "./utils/get-headers"

/**
 * @deprecated
 */
const api = (requestType = REQUEST_TYPE.CLIENT, ctx = null) => {
  return axios.create({
    baseURL: requestType === REQUEST_TYPE.CLIENT ? CLIENT_SIDE_BASE_URL : SERVER_SIDE_BASE_URL,
    headers: getHeaders(requestType, ctx),
  })
}

/**
 * @deprecated
 */
const apiRoot = (requestType = REQUEST_TYPE.CLIENT, ctx = null) => {
  return axios.create({
    baseURL: "",
    headers: {
      ...(ctx?.req?.headers && { ...ctx.req.headers }),
      ...(checkAccessToken(ctx) && { Authorization: `Bearer ${getAccessTokenFromCookies(ctx)}` }),
      "Content-Type": "application/json",
    },
  })
}

/**
 * @deprecated
 */
const apiClient = (requestType = REQUEST_TYPE.CLIENT, ctx = null) => {
  return axios.create({
    baseURL: "",
    headers: {
      ...(ctx?.req?.headers && { ...ctx.req.headers }),
      "Content-Type": "application/json",
    },
  })
}

const requestRepeaterConfig = {
  retries: 5,
  shouldResetTimeout: true,
  retryCondition: (error) => {
    // повторять, если это не 404 ошибка или message = "Limit reached"
    return !(error.response?.status === 404 || error?.response?.data?.message === "Limit reached")
  },
  retryDelay: axiosRetry.exponentialDelay,
}

function connectRequestRepeater(client) {
  axiosRetry(client, requestRepeaterConfig)
}

export { api, apiClient, apiRoot, CLIENT_SIDE_BASE_URL, connectRequestRepeater, SERVER_SIDE_BASE_URL }
