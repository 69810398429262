import { FC } from "react"
import { QRCodeCanvas } from "qrcode.react"
import { useI18n } from "next-localization"

import { GeneralSettingsInterface } from "@app/features/general-settings/types"
import { generateDesktopVCard } from "@app/features/photographer-contacts/util/download"
import { Back } from "@app/ui/back"

import styles from "./photographer-qr.module.scss"

type Props = {
  generalSettings: GeneralSettingsInterface
  onBackClick: () => void
}

const QRCodeContact: FC<Props> = ({ generalSettings, onBackClick }) => {
  const i18n = useI18n()

  return (
    <>
      <Back className={styles["back"]} text={i18n.t("common.back")} onClick={onBackClick} />
      <div className={styles["qr-code-body"]}>
        <p
          className={styles["copy-contact"]}
          dangerouslySetInnerHTML={{ __html: i18n.t("visitCardModal.scan_qrcode") }}
        />
        <QRCodeCanvas
          className={styles["qr-code"]}
          value={generateDesktopVCard(generalSettings)}
          size={240}
          level={"H"}
          minVersion={1}
        />
      </div>
    </>
  )
}

export { QRCodeContact }
