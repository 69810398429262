import React from "react"
import clsx from "clsx"

import styles from "./tooltip.module.scss"

interface TooltipProps {
  text: string
  position?: "up" | "down" | "left" | "right" | "up-left" | "up-right" | "down-left" | "down-right"
  skin?: "light" | ""
  isVisible?: boolean
  children: React.ReactNode | JSX.Element
  className?: string
}

const Tooltip = ({ text, position = "down", skin = "", isVisible, children, className }: TooltipProps) => {
  return (
    <div
      aria-label={text}
      data-tooltip-position={position}
      className={clsx(styles["root"], isVisible && styles["hidden"], skin && styles[skin], className)}
    >
      {children}
    </div>
  )
}

export { Tooltip }
