import { FC, PropsWithChildren, useEffect, useState } from "react"

import { GeneralSettingsInterface } from "@app/features/general-settings/types"
import { downloadVCard, generateMobileVCard } from "@app/features/photographer-contacts/util/download"
import { Button } from "@app/ui/button"
import { useLinkImage } from "@app/utils"

import styles from "./save-contacts.module.scss"

type Props = PropsWithChildren<{
  generalSettings: GeneralSettingsInterface
}>

const SaveContactsButton: FC<Props> = ({ generalSettings, children }) => {
  const [avatarLink, setAvatarLink] = useState(null)

  const avatarId = generalSettings.avatar?.fileKey
  const size = 320

  const [srcImage, isReadyImage] = useLinkImage(size, avatarId, null)

  useEffect(() => {
    if (srcImage && isReadyImage) {
      setAvatarLink(srcImage)
    } else {
      setAvatarLink(null)
    }
  }, [srcImage, isReadyImage])

  const handleSaveContact = async () => {
    const vCardText = await generateMobileVCard(generalSettings, avatarLink)

    if (vCardText) {
      downloadVCard(vCardText, generalSettings.name)
    }
  }

  return (
    <Button className={styles["copy-contact"]} skin="light" onClick={handleSaveContact}>
      {children}
    </Button>
  )
}

export { SaveContactsButton }
