import React from "react"

import { GoogleImage } from "@app/features/google-image-service-status/components/google-image"
import { useLinkImage } from "@app/utils"

import styles from "./avatar.module.scss"

const PhotographerAvatar = ({ imageSrc }) => {
  const imageSize = 320

  const [srcImage, isReadyImage] = useLinkImage(imageSize, imageSrc, null)

  if (!isReadyImage) return null

  return <GoogleImage className={styles["avatar"]} src={srcImage} alt="Avatar" />
}

export { PhotographerAvatar }
